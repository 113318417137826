import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

const theme = createTheme({
    // You can customize your theme here
});

const App = () => {
    const { token } = useSelector((state) => state.auth);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route path="/login" element={token ? <Navigate to="/" replace /> : <Login />} />
                    <Route path="/" element={token ? <Dashboard /> : <Navigate to="/login" replace />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default App;