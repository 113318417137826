import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL+'/api/v1/admin'  || 'http://localhost:5001/api/v1/admin'; // Adjust this to your backend URL

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const loginAdmin = (credentials) => api.post('/login', credentials);
export const getKPI = () => api.get('/kpi');
export const getBetaRequests = () => api.get('/beta-requests');
export const inviteToBeta = (email) => api.post('/invite-beta', { email });

export default api;