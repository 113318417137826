import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getKPI, getBetaRequests, inviteToBeta } from '../services/api';

export const fetchKPI = createAsyncThunk('admin/fetchKPI', async (_, { rejectWithValue }) => {
    try {
        const response = await getKPI();
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: 'Failed to fetch KPI' });
    }
});

export const fetchBetaRequests = createAsyncThunk('admin/fetchBetaRequests', async (_, { rejectWithValue }) => {
    try {
        const response = await getBetaRequests();
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: 'Failed to fetch beta requests' });
    }
});

export const sendBetaInvite = createAsyncThunk('admin/sendBetaInvite', async (email, { rejectWithValue }) => {
    try {
        const response = await inviteToBeta(email);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: 'Failed to send beta invite' });
    }
});

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        kpi: null,
        betaRequests: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchKPI.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchKPI.fulfilled, (state, action) => {
                state.isLoading = false;
                state.kpi = action.payload;
            })
            .addCase(fetchKPI.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.message || 'An error occurred';
            })
            .addCase(fetchBetaRequests.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchBetaRequests.fulfilled, (state, action) => {
                state.isLoading = false;
                state.betaRequests = action.payload;
            })
            .addCase(fetchBetaRequests.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.message || 'An error occurred';
            })
            .addCase(sendBetaInvite.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(sendBetaInvite.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(sendBetaInvite.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.message || 'An error occurred';
            });
    },
});

export default adminSlice.reducer;