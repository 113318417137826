import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBetaRequests, sendBetaInvite } from '../redux/adminSlice';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';

const BetaRequests = () => {
    const dispatch = useDispatch();
    const { betaRequests, isLoading, error } = useSelector((state) => state.admin);
    const [invitingSent, setInvitingSent] = useState({});

    useEffect(() => {
        dispatch(fetchBetaRequests());
    }, [dispatch]);

    const handleSendInvite = (email) => {
        setInvitingSent((prev) => ({ ...prev, [email]: true }));
        dispatch(sendBetaInvite(email)).then(() => {
            setInvitingSent((prev) => ({ ...prev, [email]: false }));
            dispatch(fetchBetaRequests());
        });
    };

    if (isLoading) return <Typography>Loading beta requests...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="beta requests table">
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Request Date</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {betaRequests.map((request) => (
                        <TableRow key={request.email}>
                            <TableCell component="th" scope="row">
                                {request.email}
                            </TableCell>
                            <TableCell>{new Date(request.requestDate).toLocaleDateString()}</TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSendInvite(request.email)}
                                    disabled={invitingSent[request.email]}
                                >
                                    {invitingSent[request.email] ? 'Sending...' : 'Send Invite'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BetaRequests;