import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/authSlice';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, CssBaseline, Container, Box } from '@mui/material';
import { Dashboard as DashboardIcon, People as PeopleIcon, ExitToApp as LogoutIcon } from '@mui/icons-material';
import KPIDisplay from './KPIDisplay';
import BetaRequests from './BetaRequests';

const drawerWidth = 240;

const Dashboard = () => {
    const dispatch = useDispatch();
    const [selectedSection, setSelectedSection] = useState('dashboard');

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Year at a Glance Admin
                    </Typography>
                    <IconButton color="inherit" onClick={handleLogout}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem button onClick={() => handleSectionChange('dashboard')} selected={selectedSection === 'dashboard'}>
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem button onClick={() => handleSectionChange('betaRequests')} selected={selectedSection === 'betaRequests'}>
                            <ListItemIcon><PeopleIcon /></ListItemIcon>
                            <ListItemText primary="Beta Requests" />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Container>
                    {selectedSection === 'dashboard' && <KPIDisplay />}
                    {selectedSection === 'betaRequests' && <BetaRequests />}
                </Container>
            </Box>
        </Box>
    );
};

export default Dashboard;