import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchKPI } from '../redux/adminSlice';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

const KPIDisplay = () => {
    const dispatch = useDispatch();
    const { kpi, isLoading, error } = useSelector((state) => state.admin);

    useEffect(() => {
        dispatch(fetchKPI());
    }, [dispatch]);

    if (isLoading) return <Typography>Loading KPI data...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;
    if (!kpi) return null;

    return (
        <Box sx={{ flexGrow: 1, mb: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {kpi.userCount}
                            </Typography>
                            <Typography color="text.secondary">
                                Total Users
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {kpi.calendarCount}
                            </Typography>
                            <Typography color="text.secondary">
                                Total Calendars
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {kpi.betaRequestCount}
                            </Typography>
                            <Typography color="text.secondary">
                                Pending Beta Requests
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default KPIDisplay;